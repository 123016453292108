.App {
    text-align: center;
    border-radius: 10px;
}

.center {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 35px;
    width: 75%;
    bottom: 0;
}

.column {
    float: left;
    width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

.border {
    border-radius: 5px;
    border-color: black;
}

.grid2x2 {
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.grid2x2 > div {
    display: flex;
    flex-basis: calc(50% - 40px);
    justify-content: center;
    flex-direction: column;
}

.grid2x2 > div > div {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #007bff;
}
